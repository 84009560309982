<template>
  <ek-panel title="业态规划">
    <div class="box">
      <img src="/images/components/ov/bfp/r1-bg.png" alt="" />
      <div
        class="btn-box"

        style='top: 150px;display: flex;'
      >
        <div v-for='(item,index) in list1'
             :key="index"
             :style="item.style"
             @click="handleItem(item.type)"
        >

        </div>
      </div>
      <div
        class="btn-box"
        style='top:304px;display: flex;'
      >
        <div v-for='(item,index) in list2'
             :key="index"
             :style="item.style"
             @click="handleItem(item.type)"
        >
        </div>
      </div>
            <div
              class="btn-box"
              style='bottom:300px;height: 150px;display: flex;'
            >
              <div v-for='(item,index) in list3'
                   :key="index"
                   :style="item.style"
                   @click="handleItem(item.type)"
              >
              </div>

            </div>


    </div>
  </ek-panel>
</template>

<script>
export default {
  data() {
    return {
      list1:[
        {
          type: 'bwg',
          style: {
            margin: '0 10px 0 30px',
            // 'backgroundColor': 'rgba(80,10,150,0.8)',
            width: '220px',
            height: '100%',
          }
        },
        {
          type: 'pjwh',
          style: {
            margin:'0 40px 0 70px',
            // 'background-color':'rgba(80,10,150,0.8)',
            width: '220px',height: '100%'
          }}, {
          type: 'pjc',
          style: {
            margin:'0 15px 0 45px',
            // 'background-color':'rgba(80,10,150,0.8)',
            width: '220px',height: '100%'
          }
        }
        ,
      ],
      list2:[
        {
          type: 'yr',
          style: {
            // 'backgroundColor': 'rgba(80,10,150,0.8)',
            width: '260px',
            height: '100%',
          }
        },
        {
          type: 'gz',
          style: {
            margin:'0 40px 0 70px',
            // 'background-color':'rgba(80,10,150,0.8)',
            width: '260px',height: '100%'
          }}, {
          type: 'ts',
          style: {
            margin:'0 15px 0 25px',
            // 'background-color':'rgba(80,10,150,0.8)',
            width: '260px',height: '100%'
          }
        }
        ,
      ],
      list3:[
        {
          type: 'dywhhd',
          style: {
            // 'backgroundColor': 'rgba(80,10,150,0.8)',
            width: '300px',
            height: '100%'
          }
        },
        {
          type: 'fwx',
          style: {
            margin:'0 10px ',
            // 'background-color':'rgba(80,10,150,0.8)',
            width: '300px',height: '100%'
          }},
        {
          type: 'gjj',
          style: {
            margin:'0 15px',
            // 'background-color':'rgba(80,10,150,0.8)',
            width: '300px',height: '100%'
          }
        }

      ]
    }
  },
  methods: {
    handleItem(type) {
      console.log(type)
      this.$eventBus.$emit('ov-bfp-r1map', type)
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: calc(100% - 40px);
  padding: 20px 11px;
  @include flex();
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
  .btn-box {
    position: absolute;
    width: calc(100% - 56px);
    height:70px;
    cursor: pointer;
  }
}
</style>
