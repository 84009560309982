<template>
  <div class="container">
    <l1></l1>
  </div>
</template>

<script>
import l1 from './components/l1'

export default {
  components: {
    l1
  }
}
</script>

<style scoped lang="scss">
.container {
  @include flex();
}
</style>
